<template>
    <v-row>
        <v-col>
            <v-card class="pa-4">
                <v-card-title>{{ category.title }}</v-card-title>
                <v-card-text>
                    <CategoryTabLinks />
                    <v-row class="pl-3 pb-3" />
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <td>Название</td>
                                    <td>Код</td>
                                    <td></td>
                                    <!-- <td></td> -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in categoryProducts" :key="item.id">
                                    <td>{{ item.gender.title }} - {{ item.title }}</td>
                                    <td>{{ item.code }}</td>
                                    <td align="right">
                                        <v-icon color="primary" @click="remove(item.category_product_id)">mdi-trash-can</v-icon>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
                <v-card-actions class="pa-4 d-flex justify-space-between">
                    <div>
                        <v-btn color="primary" class="mr-3" @click="create" v-if="products.length">
                            <v-icon>mdi-plus</v-icon> Добавить
                        </v-btn>
                        <v-btn color="light" class="mr-3" @click="goBack" >Назад</v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-col>

        <v-dialog
            v-model="editModal"
            width="800px"
        >
            <v-card>
                <v-card-title class="primary">
                    Товар
                </v-card-title>

                <v-card-text>
                    <v-form>
                        <v-row>
                            <v-col>
                                <v-select
                                    v-model="editCategoryProduct.product_id"
                                    label="Товар"
                                    :items="filteredProducts"
                                    item-text="title"
                                    item-value="id"
                                    outlined
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn
                        color="primary"
                        class="mr-3"
                        @click="save"
                    >Сохранить</v-btn>
                    <v-btn
                        color="light"
                        class="ml-3"
                        @click="editModal = false"
                    >Отмена</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
    import store from '@/store';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { mapState } from 'vuex';
    import CategoryTabLinks from '@/components/categories/CategoryTabLinks';

    export default {
        name: 'CategoryProducts',

        components: {
            CategoryTabLinks,
        },

        data() {
            return {
                editModal: false,
                editCategoryProduct: {}
            };
        },

        computed: {
            ...mapState('categories', {
                category: state => state.entity
            }),
            ...mapState('products', {
                products: state => state.entities
            }),
            categoryProducts() {
                return this.category && this.category.products ? this.category.products : [];
            },
            filteredProducts() {
                if(this.category && this.category.products) {
                    const productsIds = this.category.products.map(item => item.id);
                    return this.products.filter(item => {
                        return productsIds.indexOf(item.id) < 0;
                    }).map(item => {
                        return {
                            ...item,
                            title: `${ item.gender.title } - ${ item.title }`
                        }
                    });
                }
                return this.products.map(item => {
                    return {
                        ...item,
                        title: `${ item.gender.title } - ${ item.title }`
                    }
                });
            }
        },

        async mounted() {
            await store.dispatch('categories/get', { id: this.$route.params.id });
            await store.dispatch('products/fetch', {});
        },

        methods: {
            create () {
                this.editCategoryProduct = {
                    category_id: this.category.id,
                    product_id: ''
                };
                this.editModal = true;
            },
            edit(item) {
                this.editCategoryProduct = {
                    id: item.category_product_id,
                    category_id: this.category.id,
                    product_id: item.id
                };
                this.editModal = true;
            },
            async save() {
                const { id } = this.$route.params;
                try {
                    store.commit('categoryProducts/SET_ENTITY', this.editCategoryProduct);
                    await store.dispatch('categoryProducts/save', { category_id: id });
                    await store.dispatch('categories/get', { id });
                    this.editModal = false;
                } catch (error) {
                    alert('Ошибка сохранения');
                }
            },
            async remove(category_product_id) {
                const { id } = this.$route.params;
                try {
                    await store.dispatch('categoryProducts/delete', { category_id: id, id: category_product_id });
                    await store.dispatch('categories/get', { id });
                } catch (error) {
                    alert('Ошибка удаления');
                }
            },
            getBadge (status) {
                return status ? 'success' : 'error';
            },
            goBack() {
                const { id } = this.$route.params;
                if(id) {
                    this.$router.push(`/categories/${id}/detail`);
                } else {
                    this.$router.push('/categories');
                }
            },
        },
    };
</script>