<template>
    <AppTabLinks :links="list" />
</template>

<script>
    import { mapState } from 'vuex';
    import AppTabLinks from '@/components/AppTabLinks'

    export default {
        components: {
            AppTabLinks
        },

        data() {
            return {
                list: []
            }
        },
        computed: {
            ...mapState('auth', ['user']),
            isAdmin() {
                return this.user.admin;
            },
        },
        mounted() {
            this.list = [
                {
                    title: 'Карточка',
                    url: `/categories/${this.$route.params.id}/detail`,
                    disabled: !this.$route.params.id
                },
                {
                    title: 'Редактировать',
                    url: `/categories/${this.$route.params.id}/edit`
                },
                {
                    title: 'Товары',
                    url: `/categories/${this.$route.params.id}/products`,
                    disabled: !this.$route.params.id
                },
            ]
        },
    }
</script>
